import React from "react";
import FormControlLabel, {FormControlLabelProps} from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import * as Yup from "yup";
import {useTranslation} from "next-i18next";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {useFormik} from "formik";
import {OnboardingChildProps, OnboardingChildRefInterface} from "./onboarding";
import {getDocRef} from "../../lib/firebase";
import {useAuth} from "../../hooks/use-auth";
import {setDoc, serverTimestamp} from "firebase/firestore";
import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";

const StyledFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>(({theme}) => ({
    marginTop: theme.spacing(1),
    "& .MuiFormControlLabel-label": {
        fontSize: "1rem",
    }
}))




export const BasicForm = React.forwardRef<OnboardingChildRefInterface, OnboardingChildProps>(({
                                                                                                  showParentProgress,
                                                                                                  advanceParentToNextStep
                                                                                              }, ref) => {

    const {t} = useTranslation()

    const BasicFormSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(1)
            .max(50)
            .required(t("validation.required")),
        lastName: Yup.string()
            .min(1)
            .max(50)
            .required(t("validation.required")),
        email: Yup.string()
            .email(t("validation.email"))
            .required(t("validation.required")),
        agreedToTerms: Yup.bool().oneOf(
            [true],
            t("validation.terms")
        ),
    });


    const {user} = useAuth()
    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: BasicFormSchema,
        initialValues: {
            firstName: user?.firstName || "",
            lastName: user?.lastName || "",
            email: user?.email || "",
            marketingOptIn: true,
            agreedToTerms: false,
        },
        onSubmit: async (values, {setSubmitting, resetForm}) => {
            console.log("submitting form");
            showParentProgress(true);

            try {

                await setDoc(
                    getDocRef("users", user?.uid),
                    {...values, agreedToTerms: serverTimestamp()},
                    {merge: true}
                )

                advanceParentToNextStep();
            } catch (e) {
                showParentProgress(false);
                console.error("Error setting document with facebook data: ", e.message);
                // gtag("event", "exception", {
                //   description: "SettingsForm error updating document ",
                //   fatal: true,
                // });
            }
        },
    });

    React.useImperativeHandle(ref, () => ({
        //called by parent
        submitStep: () => {
            formik.handleSubmit();
        },
    }));


    return (
        <Box>
            <form>
                <Grid sx={{p: 3}} container direction={"column"} spacing={2}>
                    <Grid item>
                        <TextField
                            label={t("first-name")}
                            name='firstName'
                            required
                            error={Boolean(formik.errors.firstName) && Boolean(formik.touched.firstName)}
                            autoComplete='given-name'
                            onChange={formik.handleChange}
                            fullWidth
                            value={formik.values.firstName}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={t("last-name")}
                            name='lastName'
                            required
                            autoComplete='family-name'
                            onChange={formik.handleChange}
                            fullWidth
                            value={formik.values.lastName}
                            error={Boolean(formik.errors.lastName) && Boolean(formik.touched.lastName)}
                        />
                    </Grid>

                    <Grid item>
                        <TextField
                            label='Email'
                            name='email'
                            required
                            onChange={formik.handleChange}
                            fullWidth
                            type='email'
                            value={formik.values.email}
                            error={Boolean(formik.errors.email) && Boolean(formik.touched.email)}
                        />
                    </Grid>
                    <Grid item>
                        <StyledFormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.marketingOptIn}
                                    onChange={formik.handleChange}
                                    color='primary'
                                    id='marketingOptIn'
                                    name='marketingOptIn'
                                    style={{margin: 12, marginRight: 8}}
                                />
                            }
                            label={t("i-agree-to-marketing-email")}
                        />
                    </Grid>
                    <Grid item>
                        <StyledFormControlLabel
                            sx={{
                                ...(Boolean(formik.errors.agreedToTerms) && {
                                    color: "red"
                                })
                            }}
                            control={
                                <Checkbox
                                    checked={formik.values.agreedToTerms}
                                    onChange={formik.handleChange}
                                    color='primary'
                                    id='agreedToTerms'
                                    name='agreedToTerms'
                                    style={{margin: 12, marginRight: 8}}
                                />
                            }
                            label={<span dangerouslySetInnerHTML={{__html: t("i-agree-terms")}}/>}
                        />

                    </Grid>
                </Grid>
            </form>
        </Box>
    );
});
