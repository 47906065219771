import React, {useEffect, useState} from "react";
import Modal from "@mui/material/Modal";
import Hidden from "@mui/material/Hidden";
import Grid from "@mui/material/Grid";
import {useTranslation} from "next-i18next";
import Fade from "@mui/material/Fade";
import {useDispatch, useSelector} from "../store";
import {setShowAuthWall} from "../slices/user-session-slice";

import {Box} from "@mui/material";
import {AuthWallContent} from "./auth-wall-content";

export const AuthWall = () => {

    const {showAuthWall} = useSelector((state) => state.userSession);

    const [hasEventListener, setHasEventListener] = useState(false);

    const dispatch = useDispatch();

    // const history = useHistory();


    const {t} = useTranslation();

    // const location = useLocation();

    const handleBackButton = (event: any) => {
        event.preventDefault();
        dispatch(setShowAuthWall(false));
    };

    useEffect(() => {
        if (showAuthWall) {
            //window.alert("add event listener")
            //history.push("#authwall");
            window.addEventListener("popstate", handleBackButton, true);
            setHasEventListener(true);

        } else {
            if (hasEventListener) {
                window.removeEventListener("popstate", handleBackButton, true);
                setHasEventListener(false);
            }
            //if (location.hash === "#authwall") history.goBack();
            //window.alert("remove event listener")
        }
    }, [showAuthWall]);


    return (
        <Modal
            aria-label={"Authentication Screen"}
            open={showAuthWall}
            onClose={(e, reason) => {
                dispatch(setShowAuthWall(false));
            }}
            disableAutoFocus
            disableScrollLock
            // couldn't figure out how to raise the zIndex here, so ended up lowering the one of the new review dialog
            // style={{ zIndex: 1305 }}
            // classes={{ root: { zIndex: 1305 } }}
        >
            <Fade in={showAuthWall} timeout={400}>
                <Box
                    sx={{
                        position: "absolute",
                        top: `50%`,
                        left: `50%`,
                        transform: `translate(-50%, -50%)`,
                        backgroundColor: "#fff",
                        boxShadow: "0 5px 15px rgba(0, 0, 0, .5)",
                        textAlign: "center",
                        minWidth: 270,
                        maxWidth: {
                            xs: 700,
                            sm: 900
                        }
                    }}

                >
                    <Grid container direction='row' wrap='nowrap'>
                        <Hidden smDown>
                            <Grid item
                                  sx={{
                                      width: 300,
                                      backgroundColor: "#0c5ea7",
                                      backgroundImage: "url(\"/images/authwall-desktop-left.webp\")",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "cover",
                                  }}

                            ></Grid>
                        </Hidden>
                        <Grid item sx={{
                            p: {
                                xs: 4,
                                sm: 3
                            }
                        }}>
                            <AuthWallContent/>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    );
}
