import type {FC} from "react";
import {Box} from "@mui/material";
import {LogoOnly} from "./logo-only";
import {keyframes} from "@emotion/react";
import {useEffect} from "react";

const heartKeyFrames = keyframes`
  0% {
    transform: translate3d(251px, 0, 0) scale3d(1, 1, 1);
  }
  50% {
    transform: translate3d(261px, -6px, 0px) scale3d(.93, 1, 1);

  }
  100% {
    transform: translate3d(251px, 0, 0) scale3d(1, 1, 1);
  }
`;


export const SplashScreen: FC = () => {
    useEffect(() => {

        // body style is set to hidden on load already, so maybe this is redundant


// Applying on mount
        document.body.style.overflow = "hidden";
// Applying on unmount
        return () => {
            document.body.style.overflow = "visible";
        }
    }, [])

    return (
        <Box
            sx={{
                alignItems: "center",
                backgroundColor: "neutral.900",
                display: "flex",
                flexDirection: "column",
                height: "100vh",
                justifyContent: "center",
                left: 0,
                p: 3,
                position: "fixed",
                top: 0,
                width: "100vw",
                zIndex: 2000
            }}
        >
            <LogoOnly
                variant={"light"}
                sx={{
                    width: 200,
                    height: 90,
                    transformOrigin: "100% 100%",
                    transformStyle: "preserve-3d",
                    // '& path:nth-child(1)': {
                    //   animation: `${bounce1} 1s ease-in-out infinite`
                    // },
                    "& .LogoHeart": {
                        animation: `${heartKeyFrames} 1.4s ease-in-out infinite`
                    }
                }}
            />
        </Box>
    )
}
