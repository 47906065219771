import React, {useEffect, useRef, FC} from "react";
import {useTranslation} from "next-i18next";
import {LoadingButton} from "@mui/lab";
import {Box} from "@mui/material";
import {TwitterX} from "../icons/twitter-x";

export const TwitterButton: FC<any> = (props: any) => {

    const {t} = useTranslation();

    const {onClick, style, ...buttonProps} = props;

    const [isLoading, setLoading] = React.useState(false);

    const timerRef = useRef<NodeJS.Timeout>();

    const handleClick = (e: any) => {
        setLoading(true);
        onClick(e);
        timerRef.current = setTimeout(() => {
            setLoading(false);
        }, 30 * 1000);
    };

    useEffect(() => {
        return () => {
            if (timerRef.current) clearTimeout(timerRef.current);
        };
    }, []);

    return (
        <LoadingButton
            variant='text'
            loading={isLoading}
            fullWidth
            onClick={handleClick}
            style={{
                color: "#fff",
                textAlign: "center",
                backgroundColor: "#000000",
                justifyContent: "left",
                "&:hover": {
                    backgroundColor: "#282828"
                },
                borderRadius: 4,
                fontFamily: "Helvetica, Arial, sans-serif",
                textTransform: "none",
                whiteSpace: "nowrap",
                fontSize: 16,
                letterSpacing: ".25px",
                overflow: "hidden",
                fontWeight: 400,
                height: 40,
                padding: 0,
                paddingLeft: 5,
                paddingRight: 4,
                "&:disabled": {
                    backgroundColor: "#646464"
                },
                ...style
            }
            }
            {...buttonProps}
            startIcon={<TwitterX style={{fontSize: 30, marginLeft: 10, marginTop: 8}}/>}
        >
            <Box sx={{ml: 1, mr: 3, width: "100%", textAlign: "center"}}>{t("continue-with-twitter")}</Box>
        </LoadingButton>
    );
}
