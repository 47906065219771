import React, {createRef, useRef, useState, useMemo} from "react";
import Modal from "@mui/material/Modal";
import {useTranslation} from "next-i18next";

import Hidden from "@mui/material/Hidden";
import Grid from "@mui/material/Grid";
// @ts-ignore
import SwipeableViews from "@pjtsearch/react-swipeable-views";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import Button from "@mui/material/Button";
import MobileStepper from "@mui/material/MobileStepper";
import {BasicForm} from "./basic-form";
import {ProfileForm} from "./profile-form";
import {AlmostThere} from "./almost-there";
import {LoadingButton} from "@mui/lab";
import {useDispatch, useSelector} from "../../store";
import {setShowOnboarding, setActiveStep} from "../../slices/onboarding-slice";
import useWidth from "../../hooks/use-width";
import {useRouter} from "next-translate-routes/router";
import {useAuth} from "../../hooks/use-auth";
import {urlToFileUrl} from "next-translate-routes";
import {Box} from "@mui/material";


export interface OnboardingChildProps {
    advanceParentToNextStep: () => void,
    showParentProgress: (show: boolean) => void
}

export interface OnboardingChildRefInterface {
    submitStep: () => void
}


export const Onboarding = () => {

    const {t, i18n} = useTranslation();

    const {showOnboarding, activeStep} = useSelector((state) => state.onboarding);

    const [submittingStep, setSubmittingStep] = useState(false);

    const [formData, setFormData] = useState({});

    const dispatch = useDispatch();

    const router = useRouter()

    const {user} = useAuth()

    const fullScreen = useWidth() === "xs"

    const maxSteps = 3;


    //this is so we don't cover up those pages with modal
    const isPrivacyOrTerms = useMemo(() => {
        const pathname = urlToFileUrl(router)?.pathname
        return (pathname === "/terms" || pathname === "/privacy")
    }, [])


    const stepComponentsRef = useRef([...Array(maxSteps)].map(() => createRef<OnboardingChildRefInterface>()));

    const handleChange = (event: any) => {
        setFormData({...formData, [event.target.name]: event.target.value});
    };

    const handleBackButton = (event: any) => {

        dispatch(setShowOnboarding(false));
    };

    const handleClose = (e: any, reason: string) => {
        console.log(reason)
        if (false) {
            dispatch(setShowOnboarding(false));
        }
    };

    const handleNext = () => {
        if (stepComponentsRef.current) {
            stepComponentsRef.current[activeStep]?.current?.submitStep();
        }
    };

    const handleStepChange = (newStep: number) => {
        dispatch(setActiveStep(newStep))
    };

    const handleBack = () => {
        dispatch(setActiveStep(activeStep - 1))
    };

    // called by children
    const showProgress = (shouldShow: boolean) => {
        setSubmittingStep(shouldShow);
    };

    // called by children
    const advanceToNextStep = () => {
        console.log(`advanceToNext()`);

        setSubmittingStep(false);

        if (activeStep === maxSteps - 1) {
            dispatch(setShowOnboarding(false));

            if (
                window.location.pathname.indexOf("/rate-me/") >= 0 ||
                window.location.hash.indexOf("new-review") >= 0
            ) {
                //do nothing because we're on a rating page
            } else {

                router.push({
                    pathname: "/u/[username]",
                    query: {
                        username: user?.username
                    }
                })
            }

        } else {
            dispatch(setActiveStep(activeStep + 1));
        }
    };

    return (
        <Modal
            disableScrollLock={true}
            aria-labelledby='onboarding-modal'
            open={showOnboarding && !isPrivacyOrTerms}
            onClose={handleClose}
            disableAutoFocus={true}
            sx={{
                zIndex: 2000000001
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: `50%`,
                    left: `50%`,
                    transform: `translate(-50%, -50%)`,
                    backgroundColor: "#fff",
                    boxShadow: "0 5px 15px rgba(0, 0, 0, .5)",
                    minWidth: 270,
                    maxWidth: {
                        xs: 700,
                        sm: 900
                    },
                    ...(fullScreen && {
                        margin: 0,
                        width: "100%",
                        maxWidth: "100%",
                        height: "100%",
                        maxHeight: "none",
                        borderRadius: 0,
                    })
                }}

            >
                <Grid
                    container
                    direction='row'
                    wrap='nowrap'
                    style={{height: "100%"}}
                >
                    <Grid item
                          sx={{
                              border: "1px solid #e5e5e5",
                              minHeight: 430,
                              width: {
                                  sm: 370
                              },
                              maxWidth: {
                                  sm: 370
                              }

                          }}

                          xs={12}>
                        <div>
                            <SwipeableViews
                                disabled={true}
                                enableMouseEvents={false}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                            >

                                <BasicForm
                                    ref={stepComponentsRef.current[0]}
                                    advanceParentToNextStep={advanceToNextStep}
                                    showParentProgress={showProgress}
                                />
                                <div style={{overflow: "hidden"}}>
                                    <ProfileForm
                                        ref={stepComponentsRef.current[1]}
                                        advanceParentToNextStep={advanceToNextStep}
                                        showParentProgress={showProgress}
                                    />
                                </div>
                                <div>
                                    <AlmostThere
                                        ref={stepComponentsRef.current[2]}
                                        advanceParentToNextStep={advanceToNextStep}
                                        showParentProgress={showProgress}
                                    />
                                </div>
                            </SwipeableViews>

                            <MobileStepper
                                steps={maxSteps}
                                position={fullScreen ? "bottom" : "static"}
                                sx={{
                                    pb: {
                                        xs: 2,
                                        sm: 0
                                    }
                                }}
                                activeStep={activeStep}
                                nextButton={
                                    <div>
                                        {activeStep === maxSteps - 1 && (
                                            <Button
                                                size='small'
                                                variant='contained'
                                                color='primary'
                                                onClick={handleNext}
                                                disabled={submittingStep}
                                            >
                                                {t("done")}
                                            </Button>
                                        )}
                                        {activeStep < maxSteps - 1 && (
                                            <LoadingButton
                                                size='small'
                                                onClick={handleNext}
                                                loading={submittingStep}
                                                endIcon={<KeyboardArrowRight/>}
                                            >
                                                {t("next")}
                                            </LoadingButton>
                                        )}
                                    </div>
                                }
                                backButton={
                                    <Button
                                        size='small'
                                        onClick={handleBack}
                                        style={{
                                            visibility: activeStep === 0 ? "hidden" : "visible",
                                        }}
                                    >
                                        <KeyboardArrowLeft/>
                                        {t("back")}
                                    </Button>
                                }
                            />
                        </div>
                    </Grid>
                    <Hidden mdDown>
                        <Grid
                            item
                            sx={{
                                width: 500,
                                backgroundColor: "#0c5ea7",
                                backgroundImage: "url(\"/images/onboarding/onboarding1.webp\")",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                transition: "background-image 1s",
                                ...(activeStep === 1 && {
                                    backgroundImage: "url(\"/images/onboarding/onboarding2.webp\")",
                                }),
                                ...(activeStep === 2 && {
                                    backgroundImage: "url(\"/images/onboarding/onboarding3.webp\")",
                                })
                            }}
                        ></Grid>
                    </Hidden>
                </Grid>
            </Box>
        </Modal>
    );
}
