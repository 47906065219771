import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {HYDRATE} from "next-redux-wrapper";
import {AppThunk} from "../store";


export interface PersistedState {
    selectedCurrency: string;
}


const initialState: PersistedState = {
    selectedCurrency: "EUR",

};

const slice = createSlice({
        name: 'persisted',
        initialState,
        reducers: {
            setSelectedCurrency(state: PersistedState, action: PayloadAction<string>): void {
                state.selectedCurrency = action.payload
            }
        },
        // extraReducers: {
        //     [HYDRATE]: (state, action) => {
        //         //console.log('HYDRATE', state, action.payload);
        //         return {
        //             ...state,
        //             ...action.payload.persisted,
        //         };
        //     },
        // },

    })
;


export const persistedSlice = slice;


export const setSelectedCurrency = (currency: string): AppThunk => async (dispatch): Promise<void> => {

    dispatch(slice.actions.setSelectedCurrency(currency));
};
