import React, {useRef, useState, FC} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import ReactCrop, {Crop, centerCrop, makeAspectCrop, PixelCrop} from "react-image-crop";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import {useTranslation} from "next-i18next";
import "react-image-crop/dist/ReactCrop.css";
import useWidth from "../hooks/use-width";
import {TransitionProps} from "@mui/material/transitions";
import {ref, uploadBytes, getDownloadURL} from "@firebase/storage";
import {storage} from "../lib/firebase";
import {useDebounceEffect} from "../hooks/use-debounce";
import {useTheme} from "@mui/material/styles";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

interface PhotoCropAndUploadDialogProps {
    onFinished: (mySrc: string | null) => void,
    src: string | null,
    storageDestination: string,
    aspect: number,
    circularCrop: boolean,
}


export const PhotoCropAndUploadDialog: FC<PhotoCropAndUploadDialogProps> = ({
                                                                                onFinished,
                                                                                src,
                                                                                aspect,
                                                                                circularCrop,
                                                                                storageDestination
                                                                            }) => {

    const {t} = useTranslation()

    const theme = useTheme()

    const imageRef = useRef<HTMLImageElement>(null)

    const width = useWidth()

    const fullScreen = width == "xs"

    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()

    const [isUploading, setUploading] = useState(false);

    const onDialogClosed = () => {
        // setCrop({ aspect });
        onFinished(null);
    };


    useDebounceEffect(
        async () => {
            if (
                crop?.width &&
                crop?.height &&
                imageRef.current
            ) {
                //setCompletedCrop(crop)

            }
        },
        100,
        [crop],
    )

    // const onImageLoaded = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    //     console.log("onImageLoaded()");
    //
    //     const {width, height} = e.currentTarget
    //
    //     const sizePct = 0.8;
    //
    //
    //     const existingAspect = width / height
    //
    //     console.log(`existingAspect: ${existingAspect}, desiredAspect: ${aspect}, current Crop: ${JSON.stringify(crop)}`)
    //
    //     if (existingAspect > aspect) {
    //         crop.width = Math.floor(sizePct * width);
    //         crop.height = crop.width / aspect
    //     } else {
    //         crop.height = Math.floor(sizePct * height);
    //         crop.width = crop.height * aspect
    //
    //     }
    //
    //
    //     crop.x = Math.floor((width - crop.width) / 2);
    //     crop.y = Math.floor((height - crop.height) / 2);
    //
    //     setCrop({...crop, unit: "px"});
    // };


    const onImageLoadedAlternative = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const {width, height} = e.currentTarget;
        //console.log("onImageLoadedAlternative()")
        const crop = centerAspectCrop(width, height, aspect)

        //console.log("..calling setCrop with " + JSON.stringify(crop))
        setCrop(crop)
    }

    const getCroppedImg = (image: HTMLImageElement, c: PixelCrop, fileName: string): Promise<Blob> => {
        const canvas = document.createElement("canvas");

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const pixelRatio = window.devicePixelRatio

        canvas.width = Math.floor(c.width * scaleX * pixelRatio)
        canvas.height = Math.floor(c.height * scaleY * pixelRatio)


        const ctx = canvas.getContext("2d");

        if (!ctx) {
            throw new Error('No 2d context')
        }

        ctx.scale(pixelRatio, pixelRatio)
        ctx.imageSmoothingQuality = 'high'

        const cropX = c.x * scaleX
        const cropY = c.y * scaleY

        const scale = 1


        const rotateRads = 0
        const centerX = image.naturalWidth / 2
        const centerY = image.naturalHeight / 2

        ctx.save()

        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY)
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY)
        // 3) Rotate around the origin
        ctx.rotate(rotateRads)
        // 2) Scale the image
        ctx.scale(scale, scale)
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY)


        ctx.drawImage(
            image,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
        )

        ctx.restore()


        // ctx.drawImage(
        //     image,
        //     crop.x * scaleX,
        //     crop.y * scaleY,
        //     crop.width * scaleX,
        //     crop.height * scaleY,
        //     0,
        //     0,
        //     crop.width,
        //     crop.height
        // );

        return new Promise<Blob>((resolve, reject) => {
            // As a blob
            canvas.toBlob((blob) => {
                if (blob) {
                    // blob.name = fileName;
                    resolve(blob);
                } else {
                    reject("canvas returned empty blog")
                }

            }, "image/jpeg");
        });
    };

    const handleCropperSaveClicked = async () => {

        console.log(completedCrop)

        if (imageRef.current && completedCrop?.width && completedCrop?.height) {
            setUploading(true);
            const croppedBlob = await getCroppedImg(imageRef.current, completedCrop, "upload.jpg")
            const destinationRef = ref(storage, storageDestination); // storageDestination = user/${uid}/profile/cover_photo_${uid}.jpg

            try {
                const result = await uploadBytes(destinationRef, croppedBlob)
                console.log("Uploaded to ", result.ref.fullPath);

                const downloadUrl = await getDownloadURL(result.ref)
                setUploading(false);
                onFinished(downloadUrl);

            } catch (e) {
                console.error(e)
            }
        }
    };

    return (
        <Dialog
            sx={{
                zIndex: 2000000002
            }}
            fullScreen={fullScreen}
            open={!!src}
            onClose={(e, reason) => {
                if (reason !== "backdropClick") onDialogClosed();
            }}
            TransitionComponent={Transition}
        >
            <AppBar sx={{position: "relative"}} color="primary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        onClick={onDialogClosed}
                        aria-label="Close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography
                        variant="h6"
                        color="inherit"
                        sx={{flex: 1}}
                    ></Typography>
                    <Button color="inherit"
                            disabled={!Boolean(completedCrop)}
                            onClick={handleCropperSaveClicked}
                    startIcon={<SaveIcon sx={{marginBottom:"6px"}}
                    />}
                    >

                        {t("save")}
                    </Button>
                </Toolbar>
            </AppBar>
            <div style={{position: "relative", overflow: "hidden"}}>
                {isUploading && (
                    <LinearProgress
                        variant={"indeterminate"}
                        color={"secondary"}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            zIndex: 2000000002,
                        }}
                    />
                )}

                {Boolean(src) && <ReactCrop
                    keepSelection={true}
                    crop={crop}
                    onComplete={(c) => {
                        //console.log("ReactCrop: onComplete() ")
                        setCompletedCrop(c)
                    }}
                    aspect={aspect}
                    onChange={(_, newCrop) => {
                        //console.log("ReactCrop: onChange() ")
                        setCrop(newCrop)
                    }}
                    circularCrop={circularCrop}
                >
                    <img ref={imageRef} src={src || ""} style={{userSelect: "none", pointerEvents: "none"}}
                         onLoad={onImageLoadedAlternative}
                         alt={""}/>
                </ReactCrop>}

            </div>
        </Dialog>
    );
}
