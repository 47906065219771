import React, {useEffect, useRef, FC} from "react";
import FacebookIcon from "mdi-material-ui/Facebook";
import {useTranslation} from "next-i18next";
import {LoadingButton} from "@mui/lab";
import {Box} from "@mui/material";

export const FacebookButton: FC<any> = (props: any) => {

    const {t} = useTranslation();

    const {onClick, style, ...buttonProps} = props;

    const [isLoading, setLoading] = React.useState(false);

    const timerRef = useRef<NodeJS.Timeout>();

    const handleClick = (e: any) => {
        setLoading(true);
        onClick(e);
        timerRef.current = setTimeout(() => {
            setLoading(false);
        }, 30 * 1000);
    };

    useEffect(() => {
        return () => {
            if (timerRef.current) clearTimeout(timerRef.current);
        };
    }, []);

    return (
        <LoadingButton
            variant="text"
            loading={isLoading}
            fullWidth
            onClick={handleClick}
            style={{
                color: "#fff",
                backgroundColor: "#4468b0",
                justifyContent: "left",
                "&:hover": {
                backgroundColor: "#003e80"
            },
                borderRadius: 4,
                fontFamily: "Helvetica, Arial, sans-serif",
                textTransform: "none",
                whiteSpace: "nowrap",
                fontSize: 16,
                letterSpacing: ".25px",
                overflow: "hidden",
                fontWeight: 400,
                height: 40,
                padding: 0,
                paddingLeft: 5,
                paddingRight: 4,
                "&:disabled": {
                backgroundColor: "#e6e6e6"
            },
            ...style}
        }
            {...buttonProps}
            startIcon={<FacebookIcon style={{fontSize: 30, marginLeft: 8}}/>}
        >
            <Box sx={{mx:1}}>{t("continue-with-facebook")}</Box>
        </LoadingButton>
    );
}
