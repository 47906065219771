import {SlimUserInterface} from "seacrush-core"
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {HYDRATE} from "next-redux-wrapper";
import {AppThunk} from "../store";

interface RecentUsersState {
    recentUsers: SlimUserInterface[]
}

const initialState: RecentUsersState = {
    recentUsers: []
};

const slice = createSlice({
    name: "recentUsers",
    initialState,
    reducers: {
        setRecentUsers(state: RecentUsersState, action: PayloadAction<SlimUserInterface[]>): void {
            state.recentUsers = action.payload
        },
    },
    extraReducers: {
        [HYDRATE]: (state, action) => {
            //console.log('HYDRATE', state, action.payload);
            return {
                ...state,
                ...action.payload.recentUsers,
            };
        },
    },
});


export const fetchRecentUsers = (): AppThunk => async (dispatch): Promise<void> => {

        const recentUsersResponse = await fetch(`https://${process.env.NEXT_PUBLIC_SEACRUSH_HOST}/api/users/recent`)
        const recentUsers = await recentUsersResponse.json()
        dispatch(slice.actions.setRecentUsers(recentUsers));

};

export const recentUsersSlice = slice;
