import React from "react";
import {useTranslation} from "next-i18next";
import Typography from "@mui/material/Typography";
import {OnboardingChildRefInterface, OnboardingChildProps} from "./onboarding";
import {Box} from "@mui/material";

export const AlmostThere = React.forwardRef<OnboardingChildRefInterface, OnboardingChildProps>(
    ({showParentProgress, advanceParentToNextStep}, ref) => {

        const {t} = useTranslation();

        React.useImperativeHandle(ref, () => ({
            //called by parent
            submitStep: () => {
                advanceParentToNextStep()
            },
        }));

        return (
            <Box
                sx={{
                    p: 2
                }}
            >
                <Typography align='center' variant='h5' gutterBottom>
                    {t("respect-marine-life")}
                </Typography>

                <Typography variant='body2'>{t("profile-share-instructions")}</Typography>

                <img
                    src='/images/onboarding/onboarding_cool_network.svg'
                    style={{
                        display: "block",
                        width: 165,
                        height: 122,
                        margin: "24px auto",
                    }}
                />

                <Typography variant='body2'>{t("if-cool-get-access")}</Typography>
            </Box>
        );
    });
