import type {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import type {AppThunk} from "../store";
import {ReviewInterface, scoreProps, ScoreCard} from "seacrush-core"
import {HYDRATE} from "next-redux-wrapper";
import axios from "axios";
import {parseISO} from "date-fns";

export interface UpsertReviewInterface {
    id: string | null
    body: string
    scores: ScoreCard | null
    tripId: string
    lang: string
}

export interface AggregateRatingInterface {
    ratingValue: number
    reviewCount: number
}


export const calculateSingleRatingForReview = (review: ReviewInterface) => {
    let valueTotal = 0
    let count = 0
    for (const key of scoreProps) {
        valueTotal += review.scores[key]
        count += 1
    }

    return Math.round((valueTotal / count) * 10) / 10;
}


const calculateOverallScoreForReviews = (reviews: ReviewInterface[]): any | null => {

    // Let's start with our initial object...
    const overallScoresBase = scoreProps.reduce((acc: any, key) => {
        acc[key] = {scoreValueTotal: 0, scoreCount: 0}
        return acc
    }, {})

    const overallScores = reviews.reduce(
        (acc: any, review, index, array) => {

            scoreProps.forEach((scoreKey) => {
                // @ts-ignore
                if (review.scores && review.scores[scoreKey]) {
                    // @ts-ignore
                    acc[scoreKey].scoreCount = acc[scoreKey].scoreCount + 1;
                    // @ts-ignore
                    acc[scoreKey].scoreValueTotal = acc[scoreKey].scoreValueTotal + review.scores[scoreKey];
                }
            });

            if (index === array.length - 1) {
                let totalCount = 0;
                let totalTotal = 0;

                scoreProps.forEach((scoreKey) => {
                    acc[scoreKey]["avg"] = acc[scoreKey].scoreValueTotal / acc[scoreKey].scoreCount;
                    totalCount += acc[scoreKey].scoreCount;
                    totalTotal += acc[scoreKey].scoreValueTotal;
                });

                // @ts-ignore
                acc["overallAvg"] = Math.round((totalTotal / totalCount) * 10) / 10;
                // @ts-ignore
                acc["reviewCount"] = reviews.length;
            }
            return acc;
        }, overallScoresBase);

    if (reviews.length) {
        return overallScores
    } else {
        return null
    }

}


interface ReviewsState {
    isSubmitting: boolean
    currentUpsertReview: null | UpsertReviewInterface
    selectedTripReviews: ReviewInterface[]
    selectedAggregateRating: AggregateRatingInterface | null
    selectedOverallScores: any | null

}

const initialState: ReviewsState = {
    isSubmitting: false,
    currentUpsertReview: null,
    selectedTripReviews: [],
    selectedAggregateRating: null,
    selectedOverallScores: null
};

const slice = createSlice({
        name: "reviews",
        initialState,
        reducers: {
            setReviews(state: ReviewsState, action: PayloadAction<ReviewInterface[]>): void {
                const overallScores = calculateOverallScoreForReviews(action.payload);
                state.selectedAggregateRating = overallScores ? {
                    ratingValue: overallScores["overallAvg"],
                    reviewCount: overallScores["reviewCount"]
                } as AggregateRatingInterface : null
                state.selectedOverallScores = overallScores
                state.selectedTripReviews = action.payload.sort((a, b) =>
                    parseISO(b.timeAdded).getTime() - parseISO(a.timeAdded).getTime())

            },
            setCurrentUpsertReview(state: ReviewsState, action: PayloadAction<UpsertReviewInterface>): void {
                state.currentUpsertReview = action.payload
            },
            removeCurrentUpsertReview(state: ReviewsState): void {
                state.currentUpsertReview = null
            },
            setSubmitting(state: ReviewsState, action: PayloadAction<boolean>): void {
                state.isSubmitting = action.payload
            }
        },
        extraReducers: {
            [HYDRATE]: (state, action) => {
                //console.log('HYDRATE', state, action.payload);
                return {
                    ...state,
                    ...action.payload.reviews,
                };
            },
        },
    })
;

export const reviewsSlice = slice;

export const fetchReviewsByTag = (tag: string): AppThunk => async (dispatch): Promise<void> => {
    const reviewsUrl = `https://${process.env.NEXT_PUBLIC_SEACRUSH_HOST}/api/reviews?tag=${tag}`;
    // console.log(reviewsUrl)
    const reviewsResponse = await fetch(reviewsUrl)
    const reviews = await reviewsResponse.json()
    dispatch(slice.actions.setReviews(reviews));
};

export const openNewUpsertDialog = (lang: string): AppThunk => async (dispatch, getState): Promise<void> => {

    const tripId = getState().userSession.selectedTripId

    const newScoreCard: ScoreCard = {
        "marine-life": 0,
        value: 0,
        sustainability: 0,
        professionalism: 0
    }

    dispatch(slice.actions.setCurrentUpsertReview({
        id: null,
        body: "",
        scores: newScoreCard,
        tripId,
        lang
    }));

};

export const openEditUpsertDialog = (review: ReviewInterface): AppThunk => async (dispatch, getState): Promise<void> => {

    const tripId = getState().userSession.selectedTripId

    dispatch(slice.actions.setCurrentUpsertReview({
        id: review.id,
        body: review.body,
        scores: review.scores,
        tripId,
        lang: review.detectedLang
    }));

};

export const closeUpsertDialog = (): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.removeCurrentUpsertReview());
}

export const deleteReview = (reviewId: string): AppThunk => async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setSubmitting(true));
    try {
        console.log(`set review ${reviewId}  as deleted`);
        const res = await axios.delete(`/api/reviews/${reviewId}`, {
            headers: {
                "content-type": "application/json",
            },
        });

        if (res.status === 200) {
            dispatch(slice.actions.removeCurrentUpsertReview());
            dispatch(fetchReviewsByTag(getState().userSession.selectedTripId));
        }

    } catch (e) {
        console.log(e);
    } finally {
        dispatch(slice.actions.setSubmitting(false));
    }
}

export const upsertReview = (upsertReview: UpsertReviewInterface): AppThunk => async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setSubmitting(true));
    try {
        console.log(`submitting upsert review`);
        let res
        if (!upsertReview.id || upsertReview.id === "new") {
            res = await axios.post(`/api/reviews/new`, upsertReview, {
                headers: {
                    "content-type": "application/json",
                },
            });
        } else {
            res = await axios.put(`/api/reviews/${upsertReview.id}`, upsertReview, {
                headers: {
                    "content-type": "application/json",
                },
            });
        }


        if (res.status === 200) {
            dispatch(slice.actions.removeCurrentUpsertReview());
            dispatch(fetchReviewsByTag(getState().userSession.selectedTripId));
        }

    } catch (e) {
        console.log(e);
    } finally {
        dispatch(slice.actions.setSubmitting(false));
    }

}

export const setVerifiedReview = (reviewId: string, verified: boolean): AppThunk => async (dispatch, getState): Promise<void> => {
    dispatch(slice.actions.setSubmitting(true));
    try {
        console.log(`set review ${reviewId}  as toggle verify`);
        const res = await axios.get(`/api/reviews/${reviewId}?verified=${verified}`, {
            headers: {
                "content-type": "application/json",
            },
        });

        if (res.status === 200) {
            dispatch(fetchReviewsByTag(getState().userSession.selectedTripId));
        }

    } catch (e) {
        console.log(e);
    } finally {
        dispatch(slice.actions.setSubmitting(false));
    }
}

