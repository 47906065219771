interface SEOType {
    [key: string]: any;
}

export const SEObyLang: SEOType = {
    "en": {
        openGraph: {
            type: 'website',
            locale: "en_US",
            url: 'https://www.seacrush.com/',
            siteName: 'SeaCrush',
            images: [{
                "@type": "ImageObject",
                "height": "512",
                "url": "https://www.seacrush.com/images/seacrush_logo_square.png",
                "width": "512"
            }],
        },
    },
    "fr": {
        openGraph: {
            type: 'website',
            locale: "fr_FR",
            url: 'https://www.seacrush.com/',
            siteName: 'SeaCrush',
        },
    }
};


export const OrganizationData = {
    type: "Organization",
    url: "https://www.seacrush.com",
    id: "https://www.seacrush.com/#organization",
    logo: "https://www.seacrush.com/images/seacrush_logo_square.png",
    email: "hello@seacrush.com",
    founders: [
        {"@type": "Person", "name": "Isabelle Barbier"},
        {"@type": "Person", "name": "Michael Lake"}],
    legalName: "SeaCrush SASU",
    name: "SeaCrush",
    contactPoint: [
        {
            telephone: '+1-888-930-5899',
            contactType: 'customer service',
            email: 'hello@seacrush.com',
            areaServed: 'US',
            availableLanguage: ['English', 'French']
        },
        {
            telephone: '+33 7 57 90 31 36',
            contactType: 'customer service',
            email: 'hello@seacrush.com',
            areaServed: 'FR',
            availableLanguage: ['French']
        }
    ],
    sameAs: [
        "https://www.facebook.com/SeaCrush",
        "https://twitter.com/SeaCrush",
        "https://www.instagram.com/seacrushexpeditions",
        "https://www.linkedin.com/company/seacrush",
        "https://www.crunchbase.com/organization/seacrush"
    ]
};
