import {useDispatch as useReduxDispatch, useSelector as useReduxSelector} from 'react-redux';
import type {TypedUseSelectorHook} from 'react-redux';
import type {ThunkAction} from 'redux-thunk';
import {configureStore, EnhancedStore, AnyAction} from "@reduxjs/toolkit";
import type {Action} from '@reduxjs/toolkit';
import {rootReducer} from './root-reducer';
import {setupListeners} from "@reduxjs/toolkit/query";
import {firestoreApi} from "../slices/firestore-slice";
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {createWrapper} from "next-redux-wrapper";
import {Persistor} from "redux-persist/es/types";
import { ThunkDispatch } from 'redux-thunk';


export interface CustomStore extends EnhancedStore {
    __persistor: Persistor;
}


const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['userSession', "reviews", "firestoreSlice", "rateUser"]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const makeStore = () => {

    const store =  configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({serializableCheck: false}).concat(firestoreApi.middleware),
        devTools: process.env.NEXT_PUBLIC_REACT_APP_ENABLE_REDUX_DEV_TOOLS === "true"
    });

    const isServer = typeof window === "undefined";

    if (!isServer) { // only in browser
        // @ts-ignore
        (store as CustomStore).__persistor = persistStore(store);
        setupListeners(store.dispatch)
    }

    return store
}


export type AppStore = ReturnType<typeof makeStore>;

export type RootState = ReturnType<AppStore["getState"]>;

export type AppDispatch = AppStore["dispatch"];

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;

// hooks
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export const useDispatch = () => useReduxDispatch<AppDispatch>();


export const wrapper = createWrapper<AppStore>(makeStore, { debug: false });


// export const persistor = persistStore(makeStore)
//
//
// setupListeners(makeStore.dispatch)

