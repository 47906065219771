import {createSlice} from "@reduxjs/toolkit";
import type {PayloadAction} from "@reduxjs/toolkit";
import type {AppThunk} from "../store";
import {tripResultsApi} from "../frontend-api/trip-api";
import {TripInterface} from "../types/trip";
import {SlimUserInterface} from "seacrush-core"
import {HYDRATE} from "next-redux-wrapper";

interface TripsState {
    trips: {
        tripsArray: TripInterface[];
        byId: Record<string, TripInterface>;
        allIds: string[];
    };
    isLoaded: boolean;
    criteria: {
        selectedContinent: string;
        selectedPeriod: string;
    },
    interestedByTripId: Record<string, SlimUserInterface[]>;
    isLoadingInterested: boolean
}

const initialState: TripsState = {
    trips: {
        tripsArray: [],
        byId: {},
        allIds: []
    },
    isLoaded: false,
    criteria: {
        selectedContinent: "",
        selectedPeriod: ""
    },
    interestedByTripId: {},
    isLoadingInterested: false
};

const slice = createSlice({
    name: "trips",
    initialState,
    reducers: {
        getTrips: function (state: TripsState, action: PayloadAction<TripInterface[]>): void {
            const trips = action.payload;

            state.trips.byId = trips.reduce((
                accumulator,
                current
            ) => {
                // @ts-ignore
                accumulator[current.tripId] = current
                return accumulator;
            }, {});

            state.trips.tripsArray = trips

            state.trips.allIds = Object.keys(state.trips.byId);
            state.isLoaded = true
        },
        showLoading(state: TripsState): void {
            state.isLoaded = false;
        },
        setCriteriaContinent(state: TripsState, action: PayloadAction<string>): void {
            state.criteria.selectedContinent = action.payload
        },
        setCriteriaPeriod(state: TripsState, action: PayloadAction<string>): void {
            state.criteria.selectedPeriod = action.payload
        },
        setInterestedByTripId(state: TripsState, action: PayloadAction<any>): void {
            state.interestedByTripId = {...state.interestedByTripId, ...action.payload}
        },
        showInterestedLoading(state: TripsState, action: PayloadAction<boolean>): void {
            state.isLoadingInterested = action.payload
        },
    },

    // extraReducers: {
    //     [HYDRATE]: (state, action) => {
    //         //console.log('HYDRATE', state, action.payload);
    //         return {
    //             ...state,
    //             ...action.payload.trips,
    //         };
    //     },
    // },
});

export const tripsSlice = slice

export const getTrips = ({language}: { language: string }): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.showLoading());

    const data = await tripResultsApi.getTrips(language);

    dispatch(slice.actions.getTrips(data));
};

export const setCriteriaContinent = (continentId: string): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.setCriteriaContinent(continentId))
};

export const setCriteriaPeriod = (period: string): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.setCriteriaPeriod(period))
};


export const fetchInterestedDivers = (tripIds: string[]): AppThunk => async (dispatch): Promise<void> => {

    dispatch(slice.actions.showInterestedLoading(true))

    const trip_ids = tripIds.join(",");

    const url = `https://${process.env.NEXT_PUBLIC_SEACRUSH_HOST}/api/interested-divers?trip_ids=${trip_ids}`;
    console.log(url)
    const response = await fetch(url);

    const interestedByTripId = await response.json();

    dispatch(slice.actions.setInterestedByTripId(interestedByTripId))

    dispatch(slice.actions.showInterestedLoading(false))

};

