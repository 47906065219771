import {Avatar} from "@mui/material";
import {FC} from "react";
import NextLink from "next-translate-routes/link";
import {styled} from "@mui/material/styles";
import {SlimUserInterface} from "seacrush-core"

interface UserAvatarProps {
    slimUser: SlimUserInterface
    alternateBackgroundColor?: string;

}

const StyledFlag = styled("img")(({theme}) => ({
    position: "absolute",
    width: 18,
    height: 18,
    left: -6,
    bottom: 0
}))

export const UserAvatar: FC<UserAvatarProps> = ({alternateBackgroundColor, slimUser}) => {

    const {photoUrl, username, homeCountryCode, accepted} = slimUser

    const avatar = <Avatar
        sx={{
            marginTop: "10px",
            marginLeft: "-14px",
            marginRight: "2px",
            marginBottom: "2px",
            width: "60px",
            height: "60px",
            "&:hover": {
                width: "64px",
                height: "64px",
                marginTop: "6px",
                marginLeft: "-16px",
                marginRight: "0",
            },
        }}
        src={photoUrl}
        alt={username || "profile image"}
        style={{

            borderWidth: 3,
            borderStyle: "solid",
            borderColor:
                accepted ? "#0d8093" : (alternateBackgroundColor ? alternateBackgroundColor : "white")
        }}
    />;

    if (username) {
        return <NextLink
            href={{
                pathname: "/u/[username]",
                query: {username}
            }}
            passHref
            legacyBehavior
        >
            <a style={{
                display: "inline-block",
                position: "relative"
            }}>
                {avatar}
                {homeCountryCode && <StyledFlag src={`/images/flags/${homeCountryCode}.png`} alt={homeCountryCode}/>}
            </a></NextLink>
    } else {
        return <div style={{
            display: "inline-block",
            position: "relative"
        }}>
            {avatar}
            {homeCountryCode && <StyledFlag src={`/images/flags/${homeCountryCode}.png`} alt={homeCountryCode}/>}
        </div>
    }


}

