import { createSvgIcon } from '@mui/material/utils';

export const TwitterX = createSvgIcon(

    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        height="20"
        width="20"
        viewBox="0 0 24 24">
      <path fill="currentColor" d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584l-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z"/>
    </svg>,
  'TwitterX'
);
