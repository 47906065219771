import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import type {AppThunk} from '../store';
import {UserInterface} from "seacrush-core";
import {setDoc, writeBatch, collection, getDocs} from "firebase/firestore";
import {firestore, getDocRef} from "../lib/firebase";
import {DiverCertification} from "seacrush-core"
import {HYDRATE} from 'next-redux-wrapper';
import {User} from "../types/user";
import {fetchInterestedDivers} from "./trips-slice";

export interface UserSessionState {
    showAuthWall: boolean;
    selectedTripId: string;
    currentProfile: UserInterface;
    isUpdatingProfile: boolean;
}

const initialState: UserSessionState = {
    showAuthWall: false,
    isUpdatingProfile: false,
    selectedTripId: "aqua_galapagos",
    currentProfile: {
        accepted: false,
        username: "",
        uid: "",
        photoUrl: "",
        photoHash: "",
        homeCountryCode: "",
        joined: null,
        gender: null,
        birthday: "",
        currentLocation: "",
        firstName: "",
        lastName: "",
        certBody: "",
        certLevel: "",
        numberOfDives: "",
        coverUrl: "",
        bio: "",
        interested: {},
        certifications: [],
        disableWebIndexing: true
    } as UserInterface
};

const slice = createSlice({
        name: 'userSession',
        initialState,
        reducers: {
            setSelectedTripId(state: UserSessionState, action: PayloadAction<string>): void {
                state.selectedTripId = action.payload
            },
            setCurrentProfile(state: UserSessionState, action: PayloadAction<UserInterface>): void {
                state.currentProfile = action.payload
            },
            setUpdatingProfile(state: UserSessionState, action: PayloadAction<boolean>): void {
                state.isUpdatingProfile = action.payload
            },
            updateProfilePhoto(state: UserSessionState, action: PayloadAction<string>): void {
                state.currentProfile.photoUrl = action.payload
            },
            updateCoverUrl(state: UserSessionState, action: PayloadAction<string>): void {
                state.currentProfile.coverUrl = action.payload
            },
            updateProfileBio(state: UserSessionState, action: PayloadAction<string>): void {
                state.currentProfile.bio = action.payload
            },
            updateProfileInterested(state: UserSessionState, action: PayloadAction<any>): void {
                state.currentProfile.interested = action.payload
            },
            updateCertifications(state: UserSessionState, action: PayloadAction<DiverCertification[]>): void {
                const certifications = action.payload;
                state.currentProfile.certifications = certifications

                if (certifications.length > 0) {
                    state.currentProfile.certBody = certifications[0].certBody
                    state.currentProfile.certLevel = certifications[0].certLevel
                }
            },
            setShowAuthWall(state: UserSessionState, action: PayloadAction<boolean>): void {
                state.showAuthWall = action.payload
            },
        },
        extraReducers: {
            [HYDRATE]: (state, action) => {
                //console.log('HYDRATE', state, action.payload);
                return {
                    ...state,
                    ...action.payload.userSession,
                };
            },
        },

    })
;


export const userSessionSlice = slice;
export const setSelectedTripId = (tripId: string): AppThunk => async (dispatch): Promise<void> => {

    dispatch(slice.actions.setSelectedTripId(tripId));
};

export const setCurrentProfile = (profileData: UserInterface): AppThunk => async (dispatch): Promise<void> => {

    dispatch(slice.actions.setCurrentProfile(profileData));
};


export const updateCoverUrl = (coverUrl: string): AppThunk => async (dispatch, getState): Promise<void> => {

    dispatch(slice.actions.setUpdatingProfile(true))
    await setDoc(getDocRef(`users/${getState().userSession.currentProfile.uid}`), {
        coverUrl
    }, {merge: true})
    dispatch(slice.actions.updateCoverUrl(coverUrl));
    dispatch(slice.actions.setUpdatingProfile(false))
};


export const updateProfilePhoto = (photoUrl: string): AppThunk => async (dispatch, getState): Promise<void> => {

    dispatch(slice.actions.setUpdatingProfile(true))
    await setDoc(getDocRef(`users/${getState().userSession.currentProfile.uid}`), {
        photoUrl
    }, {merge: true})
    dispatch(slice.actions.updateProfilePhoto(photoUrl));
    dispatch(slice.actions.setUpdatingProfile(false))
};


export const updateProfileBio = (newBio: string): AppThunk => async (dispatch, getState): Promise<void> => {

    dispatch(slice.actions.setUpdatingProfile(true))

    await setDoc(getDocRef(`users/${getState().userSession.currentProfile.uid}`), {
        bio: newBio
    }, {merge: true})
    dispatch(slice.actions.updateProfileBio(newBio));

    dispatch(slice.actions.setUpdatingProfile(false))
};

export const updateProfileInterested = (interested: { [key: string]: boolean }): AppThunk => async (dispatch, getState): Promise<void> => {

    dispatch(slice.actions.setUpdatingProfile(true))

    await setDoc(getDocRef(`users/${getState().userSession.currentProfile.uid}`), {
        interested
    }, {merge: true})
    dispatch(slice.actions.updateProfileInterested(interested));

    dispatch(slice.actions.setUpdatingProfile(false))
};

export const updateCertifications = (certifications: DiverCertification[]): AppThunk => async (dispatch, getState): Promise<void> => {

    dispatch(slice.actions.setUpdatingProfile(true))

    const uid = getState().userSession.currentProfile.uid;

    const certSnapshot = await getDocs(collection(firestore, `users/${uid}/certifications`))

    const batch = writeBatch(firestore)

    for (const doc of certSnapshot.docs) {
        batch.delete(doc.ref)
    }

    let idx = 0
    for (const cert of certifications) {
        batch.set(getDocRef(`users/${uid}/certifications/${idx}`), {
            certBody: cert.certBody,
            certLevel: cert.certLevel,
            certLocation: cert.certLocation
        })
        idx++
    }

    if (certifications.length > 0) {
        batch.set(getDocRef(`users/${uid}`), {
                certBody: certifications[0].certBody,
                certLevel: certifications[0].certLevel
            },
            {merge: true})
    }

    await batch.commit()


    dispatch(slice.actions.updateCertifications(certifications));

    dispatch(slice.actions.setUpdatingProfile(false))
};

export const setShowAuthWall = (showAuthWall: boolean): AppThunk => async (dispatch): Promise<void> => {
    dispatch(slice.actions.setShowAuthWall(showAuthWall));
};


export const setInterestedInTrip = (user: User, tripId: string): AppThunk => async (dispatch): Promise<void> => {
    await setDoc(getDocRef(`users/${user?.uid}`), {
        interested: {...user?.interested, [tripId]: true}
    }, {merge: true})
    dispatch(fetchInterestedDivers([tripId]))

    const host = process.env.NEXT_PUBLIC_SEACRUSH_HOST ?? "www.seacrush.com";

    fetch("/api/admin-notify", {
        body: JSON.stringify({
            subject: `${user.firstName} Interested in ${tripId}`,
            uid: user.uid,
            message: `${user.firstName} expressed interest in the following trip:\n https://${host}/en/t/${tripId}`,
        }),
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post"
    })
};

