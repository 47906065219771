import {ReactNode, createContext, FC, useReducer, useEffect} from "react";
import PropTypes from "prop-types";
import {useGetForexByCurrencyQuery} from "../slices/firestore-slice";
import {useSelector} from "../store";

interface State {
    isFetching: boolean
    rates: any
    lastUpdated: number
    apiTimestamp: number
}

export interface ForexContextValue extends State {

    convertCurrency: (amount: number, fromCurrency: string) => string
    convertNoFormat: (amount: number, fromCurrency: string) => string,
}

interface ForexProviderProps {
    children: ReactNode;
}


const initialState: State = {
    isFetching: true,
    rates: null,
    lastUpdated: 0,
    apiTimestamp: 0
}

export const ForexContext = createContext<ForexContextValue>({
    ...initialState,
    convertCurrency: (amount: number, fromCurrency: string) => "...",
    convertNoFormat: (amount: number, fromCurrency: string) => "...",

});

enum ActionType {
    RATES_UPDATED = "RATES_UPDATED",
    FETCHING = "FETCHING"
}


type RatesUpdatedAction = {
    type: ActionType.RATES_UPDATED;
    payload: {
        apiTimestamp: number
        lastUpdated: number
        rates: any
    };
};

type FetchingAction = {
    type: ActionType.FETCHING;
    payload: {
        isFetching: boolean
    };
};

type Action = RatesUpdatedAction | FetchingAction;
const reducer = (state: State, action: Action): State => {
    if (action.type === ActionType.RATES_UPDATED) {
        const {apiTimestamp, lastUpdated, rates} = action.payload;
        return {
            ...state,
            isFetching: false,
            apiTimestamp,
            lastUpdated,
            rates
        };
    } else if (action.type === ActionType.FETCHING) {
        const {isFetching} = action.payload;
        return {
            ...state,
            isFetching
        }
    }
    return state;
}

export const ForexProvider: FC<ForexProviderProps> = (props) => {
    const {children} = props

    const [state, dispatch] = useReducer(reducer, initialState)

    const {selectedCurrency} = useSelector((state) => state.persisted)

    const {data, error, isLoading, isFetching} = useGetForexByCurrencyQuery(selectedCurrency)

    useEffect(() => {
        if (data) {
            const {rates, info} = data

            dispatch({
                type: ActionType.RATES_UPDATED,
                payload: {
                    lastUpdated: info.lastUpdated,
                    apiTimestamp: info.apiTimestamp,
                    rates: rates
                }
            });
        }

    }, [isLoading])

    if (error) {
        console.error(error)
    }

    useEffect(() => {
        dispatch({
            type: ActionType.FETCHING,
            payload: {
                isFetching: true
            }
        });
    }, [selectedCurrency, isFetching])


    const convertCurrency = (amount: number, fromCurrency: string) => {
        // console.log(`convertCurrency(), isLoading: ${isLoading}, isFetching: ${isFetching}`)
        if (!isLoading && !isFetching && data.rates[fromCurrency]) {
            return new Intl.NumberFormat(undefined, {
                style: "currency",
                currency: selectedCurrency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(amount / data.rates[fromCurrency]);
        } else {
            return "...";
        }
    };


    const convertNoFormat = (amount: number, fromCurrency: string) => {
        if (!isLoading && !isFetching && data.rates[fromCurrency]) {
            return Math.round(amount / data.rates[fromCurrency]) + "";
        } else {
            return "...";
        }
    };

    return (
        <ForexContext.Provider
            value={{
                ...state,
                convertCurrency,
                convertNoFormat,
            }}
        >
            {children}
        </ForexContext.Provider>)

}

ForexProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export const ForexConsumer = ForexContext.Consumer;
