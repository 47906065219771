import {combineReducers} from '@reduxjs/toolkit';
import {userSessionSlice} from '../slices/user-session-slice';
import {rateUserSlice} from '../slices/rate-user-slice';
import {tripsSlice} from '../slices/trips-slice';
import {onboardingSlice} from '../slices/onboarding-slice';
import {reviewsSlice} from '../slices/reviews-slice';
import {firestoreApi} from "../slices/firestore-slice";
import {recentUsersSlice} from '../slices/recent-users-slice';
import {persistedSlice} from '../slices/persisted-slice';

export const rootReducer = combineReducers({
    [tripsSlice.name]: tripsSlice.reducer,
    [userSessionSlice.name]: userSessionSlice.reducer,
    [rateUserSlice.name]: rateUserSlice.reducer,
    [onboardingSlice.name]: onboardingSlice.reducer,
    [reviewsSlice.name]: reviewsSlice.reducer,
    [firestoreApi.reducerPath]: firestoreApi.reducer,
    [recentUsersSlice.name]: recentUsersSlice.reducer,
    [persistedSlice.name]: persistedSlice.reducer,
});
