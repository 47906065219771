

// There's another file like this in the seacrush-web root dir that should be updated in tandem
export const i18nConfig = {
    i18n: {
        defaultLocale: 'default',
        locales: ['default','en', 'fr'],
    },
    // other i18n options now outside object:
    trailingSlash: true,

    serializeConfig: false, // because of the custom use i18next plugin
    defaultNS: "frontend",
    ns: ["frontend"],
    localePath: (locale: string, namespace: string, missing: boolean) => `public/locales/${namespace}-${locale}.json`,
    // localeStructure: "{{ns}}-{{lng}}",
    // localeExtension: "json",
    reloadOnPrerender: false, // TODO: set to true for development only

    // end other i18n

}
