import { initializeApp, getApps, getApp} from '@firebase/app';
import { firebaseConfig } from '../config';
import {
    getFirestore,
    collection,
    where,
    query,
    getDocs,
    limit,
    DocumentData,
    CollectionReference, doc, DocumentReference, collectionGroup
} from '@firebase/firestore';
import {getStorage} from "@firebase/storage";


if (!getApps().length) {
    initializeApp(firebaseConfig);
}


export const firebaseApp = getApp()
export const firestore = getFirestore()
export const storage = getStorage()


export const getDocRef = <T = DocumentData>(path: string, id?: string) => {
    const ref = id ? doc(firestore, path, id) : doc(firestore, path);

    return ref as unknown as DocumentReference<T>;
};
