import type {FC, ReactNode} from "react";
import {createContext, useEffect, useReducer} from "react";
import PropTypes from "prop-types";
import {
    GoogleAuthProvider,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    getAuth,
    signInWithCredential,
    onAuthStateChanged,
    FacebookAuthProvider,
    TwitterAuthProvider,
    User as FirebaseUser, signInWithRedirect, fetchSignInMethodsForEmail, linkWithCredential, UserCredential
} from "firebase/auth";
import {firebaseApp, firestore, getDocRef} from "../lib/firebase";
import type {User} from "../types/user";
import {doc, getDoc, onSnapshot, setDoc, writeBatch, serverTimestamp} from "firebase/firestore";
import axios from "axios";
import nookies from "nookies"
import Script from "next/script";
import {useTranslation} from "next-i18next";
import {setShowOnboarding} from "../slices/onboarding-slice";
import {useDispatch} from "../store";
import {FirebaseAuthError} from "firebase-admin/lib/utils/error";
import {FirebaseError} from "@firebase/util";

export const timeout = (ms: number) => new Promise((res) => setTimeout(res, ms));

const auth = getAuth(firebaseApp);

function getProvider(providerId: string) {
    switch (providerId) {
        case TwitterAuthProvider.PROVIDER_ID:
            return new TwitterAuthProvider();
        case FacebookAuthProvider.PROVIDER_ID:
            return new FacebookAuthProvider();
        default:
            throw new Error(`No provider implemented for ${providerId}`);
    }
}
const breakUpDisplayName = (displayName: string | null): { firstName: string, lastName: string } => {
    let firstName = ""
    let lastName = ""
    if (displayName && displayName.length > 0) {
        const names = displayName.split(" ")
        firstName = names[0]

        if (names.length > 1) {
            lastName = names[names.length - 1]
        }
    }
    return {firstName, lastName}
}

interface State {
    isInitialized: boolean;
    isAuthenticated: boolean;
    isAdmin: boolean;
    user: User | null;
    facebookScopes: string
}

export interface AuthContextValue extends State {
    platform: "Firebase";
    createUserWithEmailAndPassword: (
        email: string,
        password: string
    ) => Promise<any>;
    signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
    signInWithGoogle: () => Promise<any>;
    signInWithFacebook: () => Promise<any>;
    signInWithTwitter: () => Promise<any>;
    requestFacebookFriends: () => Promise<any>;
    logout: () => Promise<void>;
    getIdToken: () => Promise<any>;
    refreshToken: () => Promise<void> | undefined;
    deleteAccount: (reasonMessage: string) => Promise<void>;
    hasGrantedFacebookFriends: () => boolean;
}

interface AuthProviderProps {
    children: ReactNode;
}

enum ActionType {
    AUTH_STATE_CHANGED = "AUTH_STATE_CHANGED",
    USER_DATA_CHANGED = "USER_DATA_CHANGED",
    FACEBOOK_SCOPES_CHANGED = "FACEBOOK_SCOPES_CHANGED"
}

type AuthStateChangedAction = {
    type: ActionType.AUTH_STATE_CHANGED;
    payload: {
        isAuthenticated: boolean;
        isAdmin: boolean;
        user: User | null;
    };
};

type UserDataChangedAction = {
    type: ActionType.USER_DATA_CHANGED;
    payload: {
        user: User | null;
    };
};

type FacebookScopesChangedAction = {
    type: ActionType.FACEBOOK_SCOPES_CHANGED;
    payload: {
        facebookScopes: string;
    };
};

type Action = AuthStateChangedAction | UserDataChangedAction | FacebookScopesChangedAction;

const initialState: State = {
    isAuthenticated: false,
    isAdmin: false,
    isInitialized: false,
    user: null,
    facebookScopes: ""
};

function isUserEqual(facebookAuthResponse: fb.AuthResponse, firebaseUser: FirebaseUser | null) {
    if (firebaseUser) {
        const providerData = firebaseUser.providerData;
        for (let i = 0; i < providerData.length; i++) {
            if (providerData[i].providerId === FacebookAuthProvider.PROVIDER_ID &&
                providerData[i].uid === facebookAuthResponse.userID) {
                // We don't need to re-auth the Firebase connection.
                return true;
            }
        }
    }
    return false;
}


const reducer = (state: State, action: Action): State => {
    if (action.type === "AUTH_STATE_CHANGED") {
        const {isAuthenticated, isAdmin, user} = action.payload;

        return {
            ...state,
            isAuthenticated,
            isAdmin,
            isInitialized: true,
            user
        };
    } else if (action.type === "USER_DATA_CHANGED") {
        const {user} = action.payload;

        return {
            ...state,
            user
        };
    } else if (action.type === "FACEBOOK_SCOPES_CHANGED") {
        const {facebookScopes} = action.payload;

        return {
            ...state,
            facebookScopes
        };
    }

    return state;
};

export const AuthContext = createContext<AuthContextValue>({
    ...initialState,
    platform: "Firebase",
    createUserWithEmailAndPassword: () => Promise.resolve(),
    signInWithEmailAndPassword: () => Promise.resolve(),
    signInWithGoogle: () => Promise.resolve(),
    signInWithFacebook: () => Promise.resolve(),
    signInWithTwitter: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    getIdToken: () => Promise.resolve(),
    refreshToken: () => Promise.resolve(),
    deleteAccount: (reasonMessage: string) => Promise.resolve(),
    requestFacebookFriends: () => Promise.resolve(),
    hasGrantedFacebookFriends: () => false
});


const enhancePhotoUrl = (urlPath: string | null): string | null =>  {

        const twitterMatch = urlPath?.match(/(https:\/\/pbs\.twimg\.com.*\/.*)_.*?(\..*)/)
        if (twitterMatch) {
            return twitterMatch[1] + twitterMatch[2]
        }
        const facebookMatch = urlPath?.match(/.*facebook\.com.*/)
        if (facebookMatch) {
            return urlPath + "?type=large"
        }
        return null

}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
        const {children} = props;
        const [state, dispatch] = useReducer(reducer, initialState);

        const {i18n} = useTranslation()

        const otherDispatch = useDispatch()

        // const [facebook, setFacebook] = useState<FacebookStatic>()


        useEffect(() => auth.onIdTokenChanged(async (user) => {

                    console.log("useEffect auth.onIdTokenChanged()")

                    if (user) {
                        console.log("onIdTokenChanged: a firebase user exists")
                        // Here you should extract the complete user profile to make it available in your entire app.
                        // The auth state only provides basic information.

                        const {firstName, lastName} = breakUpDisplayName(user.displayName)

                        const authContextUser: User = {
                            uid: user.uid,
                            email: user.email || "hello@seacrush.com",
                            firstName,
                            lastName,
                            photoUrl: undefined,
                            username: "unknown",
                            certBody: "",
                            certLevel: "",
                            interested: {}
                        }

                        const tokenResult = await user.getIdTokenResult(false)
                        nookies.set(undefined, "token", tokenResult.token, {path: "/"});

                        //console.log(`attempt to fetch seacrush userDoc with uid: ${user.uid}`)

                        const userSnap = await getDoc(doc(firestore, "users", user.uid))

                        // console.log("user data:")
                        // console.log(userSnap.data())

                        if (userSnap.exists() && userSnap.data().email) {
                            //must be existing user..
                            console.log("onIdTokenChanged: a seacrush userdoc exists with email")
                            const {
                                firstName,
                                lastName,
                                photoUrl,
                                username,
                                interested,
                                certBody,
                                certLevel
                            } = userSnap.data() as any


                            authContextUser.firstName = firstName || ""
                            authContextUser.lastName = lastName || ""
                            authContextUser.photoUrl = photoUrl
                            authContextUser.username = username || "unknown"
                            authContextUser.certBody = certBody || "Unknown Issuer"
                            authContextUser.certLevel = certLevel || "Unknown Cert. Level"
                            authContextUser.interested = interested || {}

                        } else {
                            // user doesn't exist in db yet, so maybe we need to make a new one...
                            console.log("onIdTokenChanged: no seacrush userdoc exists")

                            const facebookUserId = user.providerData.filter((pd) => pd.providerId === "facebook.com")[0]?.uid

                            const fastFbProfileData: any = {}

                            if (facebookUserId) {

                                // process facebook stuff quickly (somewhat replicated on server, but server is too slow for onboarding)
                                console.log("facebook data START")

                                try {
                                    const fbUserData = await fetchFacebookData(facebookUserId)

                                    //take note that it's possible that our test users don't have location data

                                    const fbState = fbUserData?.location?.location?.state

                                    console.log("state ", fbState)

                                    const fbCity = fbUserData?.location?.location?.city
                                    const fbCountry = fbUserData?.location?.location?.country

                                    if (!!fbCity) {
                                        const stateFormatted = !!fbState ? ` , ${fbState}` : ""
                                        const countryFormatted = !!fbCountry ? `, ${fbCountry}` : ""
                                        fastFbProfileData["currentLocation"] = `${fbCity}${stateFormatted}${countryFormatted}`
                                    }

                                    const fbHomeCountryCode = fbUserData?.hometown?.location?.country_code

                                    if (!!fbHomeCountryCode) {
                                        fastFbProfileData["homeCountryCode"] = fbHomeCountryCode
                                    }

                                    console.log("facebook data END")
                                } catch (e) {
                                    console.error("unable to grab fb data: " + e.message)
                                }

                            }

                            const username = (firstName + lastName).normalize("NFD").replace(/[\u0300-\u036f]/g, "")

                            const batch = writeBatch(firestore)

                            batch.set(doc(firestore, "users", user.uid), {
                                joined: serverTimestamp(),
                                displayName: user.displayName,
                                firstName: firstName,
                                lastName: lastName,
                                username: username,
                                usernameLowercase: username.toLowerCase(),
                                languageCode: i18n.language,
                                email: user.email,
                                photoUrl: enhancePhotoUrl(user.photoURL)
                            }, {merge: true})

                            if (Object.keys(fastFbProfileData).length > 0) {
                                batch.set(doc(firestore, "users", user.uid), fastFbProfileData, {merge: true})
                            }

                            batch.commit().then(() => {
                                authContextUser.photoUrl = enhancePhotoUrl(user.photoURL) || undefined
                                authContextUser.username = username || "unknown"

                                // console.log('Document written with ID: ', docRef.id)
                                // gtag("event", "sign_up", {
                                //     category: "AuthWall.js",
                                //     method: userCredential.additionalUserInfo.providerId
                                // })
                                // dispatch(signedIn(true, urlAfterLogin || authWall.intendedDestination))

                                fetch("/api/admin-notify", {
                                    body: JSON.stringify({
                                        subject: "New User - " + user.displayName,
                                        uid: user.uid,
                                        message: "New user signed up",
                                    }),
                                    headers: {
                                        "Content-Type": "application/json"
                                    },
                                    method: "post"
                                })

                            }).catch((error: any) => {
                                console.error("Error setting document: ", error)
                                // gtag("event", "exception", {description: "AuthWall error adding document ", fatal: true})
                            })

                        }


                        // user doesn't exist or they haven't agreed to terms
                        if (!userSnap.exists() || !userSnap.data()?.agreedToTerms) {


                            otherDispatch(setShowOnboarding(true))
                        }

                        dispatch({
                            type: ActionType.AUTH_STATE_CHANGED,
                            payload: {
                                isAuthenticated: true,
                                isAdmin: !!tokenResult.claims.admin,
                                user: authContextUser
                            }
                        });


                    } else {
                        console.log("onIdTokenChanged: a firebase user was not passed in")
                        dispatch({
                            type: ActionType.AUTH_STATE_CHANGED,
                            payload: {
                                isAuthenticated: false,
                                isAdmin: false,
                                user: null
                            }
                        });
                    }
                },
                error => {
                    console.log(`error: ${error}`)
                }
            ),
            [dispatch]
        )
        ;


        useEffect(() => {

            if (state?.user?.uid) {
                return onSnapshot(doc(firestore, "users", state.user.uid), (userDoc) => {
                    console.log("onSnapshot() for user changing on firebase, should update auth context")
                    const {
                        firstName, lastName, photoUrl, username, email, interested, certBody, certLevel,
                        homeCountryCode,
                        currentLocation,
                        numberOfDives
                    } = userDoc.data() as any
                    //console.log(photoUrl)
                    dispatch({
                        type: ActionType.USER_DATA_CHANGED,
                        payload: {
                            user: {
                                uid: userDoc.id,
                                photoUrl: photoUrl || undefined,
                                email: email || "hello@seacrush.com",
                                firstName: firstName || "",
                                lastName: lastName || "",
                                username: username || "unknown",
                                certBody: certBody || "",
                                certLevel: certLevel || "",
                                interested: interested || {},
                                numberOfDives,
                                homeCountryCode,
                                currentLocation
                            }
                        }
                    });

                })
            }
        }, [dispatch, state.isAuthenticated]);


        const _signInWithEmailAndPassword = async (email: string, password: string): Promise<void> => {
            await signInWithEmailAndPassword(auth, email, password);
        };

        const signInWithGoogle = async (): Promise<void> => {
            const provider = new GoogleAuthProvider();

            await signInWithPopup(auth, provider);
        };

        const signInWithTwitter = async (): Promise<void> => {
            const provider = new TwitterAuthProvider();

            try {
                const credential = await signInWithPopup(auth, provider);

            } catch (error) {

                console.log(`error: ${error.message}`)
                if (error.customData.email && error.code === 'auth/account-exists-with-different-credential') {
                    const userCredential = await autoLinkCredentialsFromError(error)
                    console.log("attempting to link credential")
                    const credential = TwitterAuthProvider.credentialFromError(error);
                    if (credential) await linkWithCredential(userCredential.user,credential);
                }
            }

        };

        const hasGrantedFacebookFriends = (): boolean => {
            console.log(state.facebookScopes + "  <== scopes")
            return state.facebookScopes.indexOf("user_friends") > -1

        };

        const fetchFacebookData = (fbid: string): Promise<any> => {
            return new Promise((resolve, reject) => {

                window.FB.api("/me", {fields: "location.fields(location.fields(city, state, country, country_code)),hometown.fields(location.fields(city, state, country, country_code))"}, (res: any) => {
                    if (!res || res.error) {
                        const errMessage = !res ? "error occurred" : res.error
                        reject(errMessage)
                    }
                    resolve(res)
                })
            })
        }

        const signInWithFacebook = async (): Promise<void> => {

            console.log("signInWithFacebook()")

            // window.FB.getLoginStatus((response) => {
            //     console.log(response.status)
            // })


            // console.log("facebook: " + JSON.stringify(window.FB))

            // const provider = new FacebookAuthProvider();
            // const scopes = "public_profile,email,user_friends,user_location,user_hometown,user_gender,user_birthday".split(",")
            const scope = "public_profile,email,user_friends,user_location,user_hometown,user_gender,user_birthday"

            try {
                window.FB.login(
                    (response) => {
                        console.log(
                            "FB.login() response received");

                        dispatch({
                            type: ActionType.FACEBOOK_SCOPES_CHANGED,
                            payload: {
                                facebookScopes: response.authResponse?.grantedScopes || ""
                            }
                        });


                    },
                    {
                        scope,
                        return_scopes: true,
                    }
                );
            } catch (e) {
                console.log(e.message)
            }


            // for (const scope of scopes) {
            //     provider.addScope(scope)
            // }
            //
            // await signInWithPopup(auth, provider);
        };

        const _createUserWithEmailAndPassword = async (email: string, password: string): Promise<void> => {
            await createUserWithEmailAndPassword(auth, email, password);
        }


        const requestFacebookFriends = async () => {
            // console.log("request facebook friends? " + facebook)
            const scope = "user_friends";

            window.FB.login(
                (response) => {
                    console.log(
                        "FB.login() response received",
                        response.authResponse.grantedScopes
                    );

                    dispatch({
                        type: ActionType.FACEBOOK_SCOPES_CHANGED,
                        payload: {
                            facebookScopes: response.authResponse?.grantedScopes || ""
                        }
                    });

                    if (response.authResponse?.grantedScopes &&
                        (response.authResponse?.grantedScopes?.indexOf("user_friends") > -1)
                    ) {
                        axios(`/api/friends/refresh`, {
                            method: "post",
                            withCredentials: true,
                            headers: {"content-type": "application/json"},
                            data: {
                                fbid: response.authResponse.userID,
                                token: response.authResponse.accessToken,
                            },
                        }).then(
                            (response) => {
                                console.log("request made to get more friends");
                            },
                            (error) => console.error(error.message)
                        );
                    }
                },
                {
                    scope: scope,
                    auth_type: "rerequest",
                    return_scopes: true,
                }
            );
        }

        const signOutFirebase = async () => {
            await signOut(auth);
            nookies.destroy(undefined, "token", {path: "/"});
        }

        const logout = async (): Promise<void> => {

            if (window.FB) {
                window.FB.getLoginStatus((response) => {
                    if (response.status === "connected") {
                        window.FB.logout((newstatus)=> {
                            signOutFirebase()
                        })
                    } else {
                        signOutFirebase()
                    }
                })
            } else {
                await signOutFirebase()
            }
        };

        const deleteAccount = async (reasonMessage: string): Promise<void> => {

            if (auth.currentUser) {

                await fetch(`https://${process.env.NEXT_PUBLIC_SEACRUSH_HOST}/api/users/${auth.currentUser?.uid}/request-deletion`, {
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        message: reasonMessage
                    }),
                })

            }

        };

        const getIdToken = async (): Promise<string> => {
            return auth.currentUser?.getIdToken() || ""
        }

        const refreshToken = (): Promise<void> | undefined => {
            const user = auth.currentUser;
            if (user) {
                return user.reload().then(() => {
                    user.getIdToken(true)
                })
            }
        };

    const autoLinkCredentialsFromError = async (err: any): Promise<UserCredential> => {

        const providers = await fetchSignInMethodsForEmail(auth, err.customData.email)
        // @ts-ignore
        const firstPopupProviderMethod = providers.find(p => [
            FacebookAuthProvider.PROVIDER_ID.toString(),
            TwitterAuthProvider.PROVIDER_ID.toString(),
        ].includes(p));

        // Test: Could this happen with email link then trying social provider?
        if (!firstPopupProviderMethod) {
            throw new Error(`Your account is linked to a provider that isn't supported.`);
        }

        const linkedProvider = getProvider(firstPopupProviderMethod);
        linkedProvider.setCustomParameters({login_hint: err.customData.email});

        const result = await signInWithPopup(auth,linkedProvider);

        return result
    }

        // this gets registered before on-page FB library loads and is called on FB sign in/out
        const fbLoginStateChangedListener = (response: fb.StatusResponse) => {
            if (response.authResponse) {
                // User is signed-in Facebook.
                const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
                    unsubscribe();
                    // Check if we are already signed-in Firebase with the correct user.
                    if (response.authResponse.accessToken != null && !isUserEqual(response.authResponse, firebaseUser)) {
                        // Build Firebase credential with the Facebook auth token.
                        const credential = FacebookAuthProvider.credential(response.authResponse.accessToken);


                        try {
                            // Sign in with the credential from the Facebook user.
                            const userCredential = await signInWithCredential(auth, credential)

                            //maybe do stuff here but I think we're firing auth.onIdTokenChanged at this point??

                            const firebaseUser = userCredential.user

                            await timeout(4000)
                            console.log("timeout finished")

                            await setDoc(getDocRef(`users/${firebaseUser.uid}`), {
                                facebook: {
                                    accessToken: response.authResponse.accessToken,
                                    id: response.authResponse.userID
                                }
                            }, {merge: true})


                        } catch (error) {
                            console.log(`error: ${error.message}`)
                            if (error.customData.email && error.code === 'auth/account-exists-with-different-credential') {
                                const userCredential = await autoLinkCredentialsFromError(error)
                                console.log("attempting to link credential")
                                const credential = FacebookAuthProvider.credentialFromError(error);
                                if (credential) await linkWithCredential(userCredential.user,credential);
                            }
                        }

                    } else {
                        // User is already signed-in Firebase with the correct user.
                    }
                });
            } else {
                // User is signed-out of Facebook.
                // window.alert("signed out")
                signOutFirebase()
            }
        }

        return (
            <AuthContext.Provider
                value={{
                    ...state,
                    platform: "Firebase",
                    getIdToken: getIdToken,
                    refreshToken: refreshToken,
                    createUserWithEmailAndPassword: _createUserWithEmailAndPassword,
                    signInWithEmailAndPassword: _signInWithEmailAndPassword,
                    signInWithGoogle,
                    signInWithFacebook,
                    signInWithTwitter,
                    logout,
                    deleteAccount,
                    requestFacebookFriends,
                    hasGrantedFacebookFriends
                }}
            >
                <Script id='facebook-sdk'
                        src='//connect.facebook.net/en_US/sdk.js'
                        strategy='lazyOnload'
                        onLoad={() => {
                            // console.log("onLoad facebook sdk")
                            window.FB.Event.subscribe("auth.authResponseChange", fbLoginStateChangedListener);
                            window.FB.init({
                                appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
                                cookie: true,
                                xfbml: true,
                                version: "v18.0",
                            });

                        }}
                />
                {children}
            </AuthContext.Provider>
        );
    }
;

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
