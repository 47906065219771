import React, {FC, useState} from "react";
import {FacebookButton} from "./facebook-button";
import {TwitterButton} from "./twitter-button";
import Typography from "@mui/material/Typography";
import {styled, useTheme} from "@mui/material/styles";
import {useTranslation} from "next-i18next";
import {setShowAuthWall} from "../slices/user-session-slice";
import {useAuth} from "../hooks/use-auth";
import {useDispatch} from "../store";
import {Box, Stack} from "@mui/material";

const StyledUl = styled("ul")(({theme}) => ({}))


const StyledLi = styled("li")(({theme}) => ({
    marginBottom: theme.spacing(1),
    color: "#4f4f4f",
    fontWeight: 500,
    position: "relative",
    "&::before": {
        fontSize: 14,
        content: "'\\25BA \\0020'",
        position: "absolute",
        top: 4,
        left: -18,
    },
}))


export const AuthWallContent: FC = () => {

    const theme = useTheme()

    const {t} = useTranslation()

    const [submitting, setSubmitting] = useState(false)

    const {signInWithFacebook, signInWithTwitter} = useAuth();

    const dispatch = useDispatch()

    const facebookLoginClicked = async (event: any) => {
        setSubmitting(true);

        try {
            await signInWithFacebook();
            dispatch(setShowAuthWall(false));
        } catch (e) {
            setSubmitting(false);
        }

    };

    const twitterLoginClicked = async (event: any) => {
        setSubmitting(true);

        try {
            await signInWithTwitter();
            dispatch(setShowAuthWall(false));
        } catch (e) {
            setSubmitting(false);
        }

    };

    return (
        <Box position={"relative"}>
            <img
                src='/images/seacrush_logo_horizontal_black.svg'
                width='942'
                height='169'
                alt='SeaCrush Logo Horizontal'
                style={{
                    height: "auto",
                    width: 240,
                    marginBottom: 16,
                }}
            />
            <br/>

            <StyledUl
                sx={{
                    display: "inline-block",
                    mt: 1,
                    mb: 2,
                    mx: "auto",
                    pl: {
                        xs: 1,
                        sm: 2,
                        md: 2
                    },
                    listStyle: "none",
                    position: "relative",
                    fontSize: "1.1rem",
                    textAlign: "left",
                }}
            >
                <StyledLi>{t("sign-up-bullet-1")}</StyledLi>
                <StyledLi>{t("sign-up-bullet-2")}</StyledLi>
                <StyledLi
                    style={{color: "#000", fontWeight: 700}}
                >
                    {t("sign-up-bullet-3")}
                </StyledLi>
            </StyledUl>
            <br/>

            <Stack spacing={1}>
                <FacebookButton disabled={submitting} onClick={facebookLoginClicked}/>
                <TwitterButton disabled={submitting} onClick={twitterLoginClicked}/>
            </Stack>


            <Typography
                component={"div"}
                style={{marginTop: 4}}
                variant='caption'
            >
                <br/>
                <div
                    dangerouslySetInnerHTML={{__html: t("by-signing-in")}}
                />
            </Typography>
        </Box>
    )

}
