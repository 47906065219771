import type {TripInterface} from "../types/trip";
import {deepCopy} from "../utils/deep-copy";
import {sanityClient} from "seacrush-core"
import {localize} from "../lib/util";

const now = new Date();


// const trips: TripInterface[] = [
//     {
//         tripId: 'blah-blah_galapagos',
//         title: "My first trip title",
//     },
//     {
//         tripId: 'foo-foo_bermuda',
//         title: "My second trip title",
//     }
// ];

class TripApi {
    // getTrips(): Promise<TripInterface[]> {
    //     return Promise.resolve(deepCopy(trips));
    // }


    getTrips(language: string): Promise<TripInterface[]> {
        return new Promise((resolve, reject) => {


            const query = `
*[ _type == "diveTrip" 
    && removed != true
    && !(_id in path("drafts.**"))
]
{
    _id,
    tripId,
    title,
    offer,
    resultDescription,
    bestMonths,
    diveType,
    diveStyle,
    offerPrice,
    membersOnly,
    "country": country-> {
      slug,
      title
    },
    "continent": continent -> {
        title,
        continentId,
    },
    region,
    slug            
} | order(_id asc)`

            sanityClient
                .fetch(query)
                .then((results) => {

                    const trips: TripInterface[] = results.map((sanityResult: any) => {

                        const r = localize(sanityResult, [language, "fr"])

                        return {
                            tripId: r.tripId,
                            title: r.title,
                            region: r.region,
                            offerPrice: r.offerPrice || null,
                            diveType: r.diveType,
                            country: {
                                slug: r.country?.slug?.current,
                                title: r.country?.title
                            },
                            resultDescription: r.resultDescription,
                            slug: r.slug.current,
                            featured: r.continent?.continentId === "featured" || r.membersOnly || null,
                            membersOnly: r.membersOnly || null,
                            bestMonths: r.bestMonths,
                            offer: r.offer,
                            continent: {
                                continentId: r.continent?.continentId,
                                title: r.continent?.title
                            },
                            locationName: r.locationName || "",
                            diveOperatorName: r.diveOperatorName || ""
                        }

                    })

                    setTimeout(() => {
                        resolve(deepCopy(trips));
                    }, 50)


                }).catch((err) => {
                console.error("[Trips Api]: ", err);
                reject(new Error("Internal server error"));
            })


        });

    }

    // getEmails({ label }: { label?: string; }): Promise<Email[]> {
    //     return new Promise((resolve, reject) => {
    //         try {
    //             // Initially we make a copy of all emails.
    //             // On a real server this will be different since there will be a real DB query.
    //             let filteredEmails: Email[] = [];
    //
    //             // Get all user custom labels
    //             const customLabels = labels.reduce(
    //                 (acc: string[], label) => {
    //                     if (label.type === 'custom') {
    //                         acc.push(label.id);
    //                     }
    //
    //                     return acc;
    //                 },
    //                 []
    //             );
    //
    //             if (label && customLabels.includes(label!)) {
    //                 filteredEmails = emails.filter((email) => email.labelIds.includes(label!));
    //             } else {
    //                 switch (label) {
    //                     case undefined:
    //                     case 'inbox':
    //                         filteredEmails = emails.filter((email) => email.folder === 'inbox');
    //                         break;
    //                     case 'all':
    //                         filteredEmails = [...emails];
    //                         break;
    //                     case 'sent':
    //                     case 'trash':
    //                         filteredEmails = emails.filter((email) => email.folder === label);
    //                         break;
    //                     case 'starred':
    //                         filteredEmails = emails.filter((email) => email.isStarred);
    //                         break;
    //                     case 'important':
    //                         filteredEmails = emails.filter((email) => email.isImportant);
    //                         break;
    //                 }
    //             }
    //
    //             resolve(deepCopy(filteredEmails));
    //         } catch (err) {
    //             console.error('[Mail Api]: ', err);
    //             reject(new Error('Internal server error'));
    //         }
    //     });
    // }
    //
    // getEmail(emailId: string): Promise<Email> {
    //     return new Promise((resolve, reject) => {
    //         try {
    //             // Find the mail
    //             const email = emails.find((_mail) => _mail.id === emailId);
    //
    //             if (!email) {
    //                 reject(new Error('Email not found'));
    //                 return;
    //             }
    //
    //             resolve(deepCopy(email));
    //         } catch (err) {
    //             console.error('[Mail Api]: ', err);
    //             reject(new Error('Internal server error'));
    //         }
    //     });
    // }
}

export const tripResultsApi = new TripApi();
