import path from "path";
import log from "../cli/log";
import fs, {Dirent} from "fs";

const deleteQueryBatch = async (db: FirebaseFirestore.Firestore, query: FirebaseFirestore.Query, batchSize: number, resolve: any, reject: any) => {
    query
        .get()
        .then((snapshot) => {
            // When there are no documents left, we are done
            if (snapshot.size === 0) {
                return 0;
            }

            // Delete documents in a batch
            const batch = db.batch();
            snapshot.docs.forEach((doc) => {
                batch.delete(doc.ref);
            });

            return batch.commit().then(() => {
                return snapshot.size;
            });
        })
        .then((numDeleted: number) => {
            if (numDeleted === 0) {
                resolve();
                return;
            }

            // Recurse on the next process tick, to avoid
            // exploding the stack.
            process.nextTick(() => {
                deleteQueryBatch(db, query, batchSize, resolve, reject);
            });
        })
        .catch(reject);
};

export const deleteCollection = (db: FirebaseFirestore.Firestore, collectionPath: string, batchSize: number) => {
    const collectionRef = db.collection(collectionPath);
    const query = collectionRef.orderBy("__name__").limit(batchSize);

    return new Promise((resolve, reject) => {
        deleteQueryBatch(db, query, batchSize, resolve, reject);
    });
};

export const timeout = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const throwAnErrorForFun = () => {
    throw new Error("this error was thrown on purpose");
};

export const extractGoogleDocIdFromUrl = (url: string) => {
    let id = url.split("id=")[1];
    if (!id) {
        id = url.split("/d/")[1];
        id = id.split("/")[0]; // here we have the id
    }
    return id;
};


const getSeaCrushNextRoot = (...subPaths: string[]) => {
    // log.info("seacrushNextRoot")
    const cwd = process.cwd();
    const isVercel = cwd.indexOf("vercel") > -1
    if (isVercel) {
        const [seacrushNextParent, _] = cwd.split("path0")
        return path.join(...[seacrushNextParent, "path0", ...subPaths])
    } else {
        const [seacrushNextParent, _] = cwd.split("seacrush-next")
        return path.join(...[seacrushNextParent, "seacrush-next", ...subPaths])
    }
}

export const getSeaCrushWebRoot = (...subPaths: string[]) => {
    return getSeaCrushNextRoot(...["seacrush-web", ...subPaths])
};

export const getSeaCrushCoreRoot = (...subPaths: string[]) => {
    return getSeaCrushNextRoot(...["seacrush-core", ...subPaths])
}

const contentDir = ["public", "content"]

export const getCountriesPath = (...subPaths: string[]) => {
    return getSeaCrushWebRoot(...[...contentDir, "countries", ...subPaths])
}

export const getTripsPath = (...subPaths: string[]) => {
    return getSeaCrushWebRoot(...[...contentDir, "trips", ...subPaths])
}

export const getArticlesPath = (...subPaths: string[]) => {
    return getSeaCrushWebRoot(...[...contentDir, "articles", ...subPaths])
}

export const getDocsPath = (...subPaths: string[]) => {
    return getSeaCrushWebRoot(...[...contentDir, "docs", ...subPaths])
}

export const getGeneratedSrcRoot = (...subPaths: string[]) => {
    return getSeaCrushWebRoot(...["src", "generated", ...subPaths])
};


export const walk = (
    dir: string,
    done: (err: Error | null, results?: string[]) => void,
    filter?: (f: string) => boolean
) => {
    let results: string[] = [];
    fs.readdir(dir, {withFileTypes: true}, (err: Error | null, list: Dirent[]) => {
        if (err) {
            return done(err);
        }
        let pending = list.length;
        if (!pending) {
            return done(null, results);
        }
        list.forEach((entry: Dirent) => {
            const file = path.resolve(dir, entry.name);
            fs.stat(file, (err2, stat) => {
                if (stat.isDirectory()) {
                    walk(file, (err3, res) => {
                        if (res) {
                            results = results.concat(res);
                        }
                        if (!--pending) {
                            done(null, results);
                        }
                    }, filter);
                } else {
                    if (typeof filter === "undefined" || (filter && filter(file))) {
                        results.push(file);
                    }
                    if (!--pending) {
                        done(null, results);
                    }
                }
            });
        });
    });
};
